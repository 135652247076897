import React from "react";
import MainLayout from "src/components/layoutmain";
import { StaticImage } from "gatsby-plugin-image";

/**
 * Page contact du site numeko
 * @returns {JSX.Element}
 * @constructor
 */
export default function Contact() {
  const seoProps = {
    title: "Numeko - Contact",
    description:
      "Un projet ? Une collaboration ? Intéressé pour collaborer. Curieux. Le numérique responsable et l'écoconception te parle.",
  };
  return (
    <MainLayout seoProps={seoProps}>
      <div className={"contact"}>
        <div className={"bloc-category bloc-presentation"}>
          <h1>Un projet ? Une collaboration ?</h1>
          <hr />
          <h4>Tout simplement, une question ?</h4>
        </div>
        <div className={"bloc-category bloc-content"}>
          <div className={"description"}>
            <p>
              N’<span>hésitez</span> pas à nous contacter avec le lien
              ci-dessous&nbsp;:
            </p>
            <a className={"link-contact"} href="mailto:contact@numeko.fr">
              <span className={"numeko-icon-mail"} />
              Nous contacter
            </a>
          </div>
          <StaticImage
            className={"background-recruitment"}
            src={"../images/background_recruitment.png"}
            alt={"Alexandre Froger Fondateur et CEO de la société Numeko"}
            placeholder={"none"}
            quality={100}
            width={500}
          />
        </div>
      </div>
    </MainLayout>
  );
}
